import React, { useCallback } from 'react';
import { Ahs2024LogoColor, GlobalHeader, HsaLogoColor } from '@ftdr/blueprint-components-react';
import { Brand } from '../../constants/Brand';
import configHandler from '../../services/config';

const Header: React.FC = () => {
  const brand = configHandler.config?.site.name;

  const headerLogoRenderer = useCallback(() => {
    return brand === Brand.AHS ? <Ahs2024LogoColor height={50} /> : <HsaLogoColor height={50} />;
  }, [brand]);

  return (
    <GlobalHeader
      sticky
      constrainContentWidth
      logoRenderer={headerLogoRenderer}
      navMenuItems={[]}
      onNavMenuItemActivate={_ => {}}
      desktop={true}
    />
  );
};

export default Header;
